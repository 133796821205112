<template>
  <div class="container">
    <div class="top-box" v-if="userType==='2'">
      <div class="flex-around ">
        <div class="charts" id="contractEcharts"  v-if="showEcharts"></div>
        <div class="">
          <div class="col-10 font18 margin-bottom10">劳动合同签订</div>
          <div>
            <label>在职已签合同：</label><span style="color:#0088FF">{{areayNum}}人</span>
          </div>
          <div>
            <label>在职未签合同：</label><span style="color:#FFAB00">{{notNum}}人</span>
          </div>
          <div>
            <label>在职合同到期：</label><span style="color:#1A1A1A">{{expireNum}}人</span>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-bottom20" v-if="userType==='2'">
      <van-tabs v-model:active="active" @change="getActive">
        <van-tab title="未签合同"></van-tab>
        <van-tab title="已签合同"></van-tab>
        <van-tab title="合同到期"></van-tab>
      </van-tabs>
    </div>
    <div class="search margin-bottom20"  v-if="userType==='2'">
      <van-search v-model="value" placeholder="姓名/手机号/身份证号" left-icon="false" :right-icon="require('../../../assets/imgs/center/icon-search.svg')"  @search="getSearchList" />
    </div>
    <div class="list">
      <div class="list-title">
        <div>姓名</div>
        <div>用工单位</div>
        <div>到期时间</div>
        <div>详情</div>
      </div>
      <van-list
        v-model:loading="loading"
        :finished-text="finishedText"
        :finished="finished"
        @load="getList"
        :offset="10"
        ref="vlist"
      >
      <div class="list-text" v-for="(item,i) in list" :key="i">
        <div>{{item.person?.trueName}}</div>
        <div class="van-ellipsis">{{item.inUnit.unitName}}</div>
        <div>{{item.endTime}}</div>
        <div class="col" @click="toDetail(item.id)">查看</div>
      </div>
      </van-list>
    </div>
    <div v-if="finished && total===0" style="text-align:center;">
        <van-image
            width="240"
            height="275"
            src="https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/nothing.png"
          />
      </div>
    <!-- <div class="load-more">上划加载更多</div> -->
  </div>
</template>

<script setup name="Person">
import { getToken } from '@/utils/auth'
import * as echarts from 'echarts'
import { ref } from '@vue/reactivity'
import { getContractInfo, getContractList } from '@/api/manage'
import { onMounted } from 'vue'
import { getUserInfo } from '@/api/login'
import { useRouter } from 'vue-router'
const router = useRouter()
const userType = ref('2')
const getUser = async () => {
  const token = getToken()
  if (token) {
    const res = await getUserInfo()
    if (res.code === 200) {
      userType.value = res.data.sysUser.userType
      if (res.data.sysUser.userType === '2') {
        getInfo()
      }
    }
  }
}
getUser()
const areayNum = ref(0)
const notNum = ref(0)
const expireNum = ref(0)
const showEcharts = ref(false)
const getInfo = async () => {
  const res = await getContractInfo()
  showEcharts.value = true
  areayNum.value = res.data.areayNum
  notNum.value = res.data.notNum
  expireNum.value = res.data.expireNum
  setTimeout(() => {
    initEcharts()
  }, 500)
}
// 计算年数 (日期)
const checkYear = (date) => {
  // 当前时间
  const nowDate = new Date()
  // 传入的日期
  const parseDate = new Date(date)
  // 当前时间的年份
  const nowYear = nowDate.getFullYear()
  // 传入时间的日期
  const parseYear = parseDate.getFullYear()
  return nowYear - parseYear
}
const getActive = (number) => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(number)
}
const getSearchList = () => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(active.value)
}
const pageSize = ref(20)
const pageNum = ref(1)
const loading = ref(false)
const finishedText = ref('')
const finished = ref(false)
const list = ref([])
const total = ref(0)
const value = ref('')
const getList = (num) => {
  let type = 1
  if (num) {
    type = parseInt(num) + 1
  }
  const params = { pageNum: pageNum.value, pageSize: pageSize.value }
  const data = { status: type, selectContent: value.value }
  loading.value = true
  getContractList(params, data).then((res) => {
    loading.value = false
    if (res.code === 200) {
      if (res.rows && res.rows.length > 0) {
        for (const item of res.rows) {
          if (item.person) {
            item.person.age = item.person.birthday ? checkYear(item.person.birthday) : 0
          }
        }
        list.value = list.value.concat(res.rows)
        total.value = res.total
        if (list.value >= res.total || res.total <= pageSize.value) {
          finished.value = true
          finishedText.value = '没有更多数据了'
        } else {
          finished.value = false
          pageNum.value = pageNum.value + 1
        }
      } else {
        finished.value = true
        total.value = res.total
      }
    } else {
      finished.value = true
    }
  })
}
const toDetail = (id) => {
  console.log('id', id)
  router.push({
    path: '/contractdetail',
    query: {
      id: id
    }
  })
}
const active = ref(0)
const initEcharts = () => {
  const myChart = echarts.init(
    document.getElementById('contractEcharts')
  )
  const option = {
    color: ['#0088FF', '#FFAB00', '#05c160'],
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['65%', '90%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        // itemStyle: {
        //   borderRadius: 10,
        //   borderColor: '#fff',
        //   borderWidth: 2
        // },
        label: {
          show: true,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: areayNum.value, name: '已签' },
          { value: notNum.value, name: '未签' },
          { value: expireNum.value, name: '合同到期' }
        ]
      }
    ]
  }
  myChart.setOption(option)
}
onMounted(() => {
  // initEcharts()
})

</script>
<style>
.search .van-field__clear,.search .van-field__right-icon{
  margin-right: 15px;
}
</style>
<style scoped>
.container {
  background: #fff;
  padding: 20px 15px 0;
  min-height: calc(100vh - 66px);
}
.top-box{
  padding: 25px 15px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #bdbdbd;
  background-color: rgba(248, 250, 255, 1);
  margin-bottom: 20px;
}
.top-box .charts{
  width: 110px;
  height: 110px;
}
.top-box .flex-item{
  display: flex;
  /* width: 50%; */
  justify-items: center;
}
.top-box .num{
  font-size: 14px;
  padding-left: 10px;
}
.top-box .nums{
  font-size: 20px;
}
.top-box .name{
  margin: 35px 0 15px;
  font-size: 14px;
}
.top-box .table{
  display: flex;
  justify-content: space-around;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 0;
}
.top-box .table-text{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.list{
  font-size: 14px;
}
</style>
