<template>
  <div class="container">
    <div class="outer">
      <div class="top">
        <div>{{info.inPerson?.trueName}}</div>
        <div>{{info.inPerson?.sex === '0'?'男':'女'}}</div>
        <div>{{info.inPerson?.age}}岁</div>
        <div>{{info.inUnit?.unitName}}</div>
      </div>
      <div class="inner-box">
        <div>
          <label>身份证号：</label>{{info.inPerson?.idcard}}
        </div>
        <!-- <div>
          <label>工号：</label>{{info.inPerson.}}
        </div> -->
        <!-- <div>
          <label>部门：</label>{{}}
        </div> -->
        <div>
          <label>预支工资：</label>{{info.money}}
        </div>
        <div>
          <label>审批状态：</label><span class="col" v-if="info.status === '1'">待审核</span><span class="col" v-if="info.status === '2'">同意</span><span class="col" v-if="info.status === '3'">拒绝</span>
        </div>
        <div>
          <label>申请时间：</label>{{info.createTime}}
        </div>
        <!-- <div>
          <label>审批时间：</label>{{}}
        </div> -->
        <!-- <div>
          <label>审批人：</label>张文涛
        </div> -->
      </div>
    </div>
  </div>
</template>

<script setup name="SalaryDetail">
import { useRoute } from 'vue-router'
import { getAdvanceDetail } from '@/api/manage'
import { ref } from '@vue/reactivity'
const info = ref({})
const route = useRoute()
const id = route.query.id
// 计算年数 (日期)
const checkYear = (date) => {
  // 当前时间
  const nowDate = new Date()
  // 传入的日期
  const parseDate = new Date(date)
  // 当前时间的年份
  const nowYear = nowDate.getFullYear()
  // 传入时间的日期
  const parseYear = parseDate.getFullYear()
  return nowYear - parseYear
}
const getDetail = () => {
  getAdvanceDetail(id).then((res) => {
    if (res.code === 200) {
      res.data.inPerson.age = res.data.inPerson.birthday ? checkYear(res.data.inPerson.birthday) : 0
      info.value = res.data
    }
  })
}
getDetail()
</script>
<style scoped>
.container{
  padding: 20px 15px;
}
.outer{
  border-radius: 10px 10px 12px 12px;
  background: #315CEB;
}
.outer .top{
  color: #fff;
  font-size: 16px;
  line-height: 66px;
  display: flex;
  justify-content: space-around;
}
.inner-box{
  border-radius: 10px 10px;
  background: #fff;
  padding: 30px 0 15px;
  color: #1A1A1A 100%;
  font-size: 13px;

}
.inner-box div{
  margin-bottom: 15px;
  padding: 0 20px;
}
.inner-box label{
  width: 65px;
  color: #9e9e9e;
}
</style>
