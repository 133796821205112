<template>
  <div class="container">
    <div class="outer">
      <div class="top">
        <div>{{info.inPerson?.trueName}}</div>
        <div>缴纳基数：{{info.inUnit?.base}}</div>
      </div>
      <div class="inner-box">
        <div class="div">
          <label>身份证号：</label>{{info.inPerson?.idcard}}
        </div>
        <div class="div">
          <label>参保日期：</label>{{info.insureTime}}
        </div>
        <div class="list">
          <div class="list-title">
            <div>参保险种</div>
            <div>基数</div>
            <div>单位缴</div>
            <div>个人缴</div>
          </div>
          <div class="list-text" v-for="(item,i) in info.insuranceInsureCategoryList" :key="i">
            <div class="van-ellipsis">{{item.inInsureCategory.name}}</div>
            <div>{{info.inUnit?.base}}</div>
            <div>{{item.inUnitInsureCategory.unitPercent}}%</div>
            <div>{{item.inUnitInsureCategory.personPercent}}%</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="MedicalDetail">
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { getInsuranceDetail } from '@/api/manage'
const info = ref({})
const route = useRoute()
const id = route.query.id

const getDetail = () => {
  getInsuranceDetail(id).then((res) => {
    if (res.code === 200) {
      info.value = res.data
    }
  })
}
getDetail()

</script>
<style scoped>
.container{
  padding: 20px 15px;
}
.outer{
  border-radius: 10px 10px 12px 12px;
  background: #315CEB;
}
.outer .top{
  color: #fff;
  font-size: 16px;
  line-height: 66px;
  display: flex;
  justify-content: space-around;
}
.inner-box{
  border-radius: 10px 10px;
  background: #fff;
  padding: 30px 0 15px;
  color: #1A1A1A 100%;
  font-size: 13px;
}
.inner-box .div{
  margin-bottom: 15px;
  padding: 0 20px;
}
.inner-box label{
  width: 65px;
  color: #9e9e9e;
}
.list{
  font-size: 12px;
  padding: 0;
  margin: 0!important;
}
.list .list-title{
  line-height: 64px;
  color: #101010;
  background:  rgba(108, 99, 255, 0.04);
  display: flex;
  justify-content: space-around;
}
.list .list-title div,.list .list-text div{
  flex: 1;
}
.list .list-text{
  line-height: 54px;
  color: #616161;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(245, 245, 245, 1);
}
</style>
