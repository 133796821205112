<template>
  <div class="container">
    <div class="top-box"  v-if="userType==='2'">
      <div class="flex-around">
        <div class="charts" id="insuranceEcharts" v-if="showEcharts"></div>
        <div class="margin-top10">
          <div class="col-10 font18 margin-bottom10">参保统计</div>
          <div>
            <label>已参保人员：</label><span style="color:#0088FF">{{onNum}}人</span>
          </div>
          <div>
            <label>未参保人员：</label><span style="color:#FFAB00">{{offNum}}人</span>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top20" v-if="userType==='2'">
      <van-tabs v-model:active="active" @change="getActive">
        <van-tab title="已参保"></van-tab>
        <van-tab title="未参保"></van-tab>
        <van-tab title="本月参保"></van-tab>
      </van-tabs>
    </div>
    <div class="search margin-top20 margin-bottom20"  v-if="userType==='2'">
      <van-search v-model="value" placeholder="姓名/手机号/身份证号" left-icon="false" :right-icon="require('../../../assets/imgs/center/icon-search.svg')" @search="getSearchList" />
    </div>
    <div class="list">
      <div class="list-title">
        <div>{{userType==='2'?'姓名':'月份'}}</div>
        <div>{{active === 1?'身份证号':'基数'}}</div>
        <div>{{active === 1?'联系方式':'详情'}}</div>
      </div>
      <van-list
        v-model:loading="loading"
        :finished-text="finishedText"
        :finished="finished"
        @load="getList"
        :offset="10"
        ref="vlist"
      >
      <div class="list-text" v-for="(item,i) in list" :key="i">
        <div v-if="active !== 1">{{userType==='2'?item.inPerson?.trueName:item.month}}</div>
        <div v-if="active === 1">{{userType==='2'?item.trueName:item.month}}</div>
        <div v-if="active !== 1">{{item.inUnit?.base}}</div>
        <div v-if="active === 1">{{item.idcard}}</div>
        <div v-if="active !== 1" class="col" @click="toDetail(item.id)">查看</div>
        <div v-if="active === 1">{{item.phone}}</div>
      </div>
      </van-list>
    </div>
    <div v-if="finished && total===0" style="text-align:center;">
        <van-image
            width="240"
            height="275"
            src="https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/nothing.png"
          />
      </div>
    <!-- <div class="load-more">上划加载更多</div> -->
  </div>
</template>

<script setup name="Insurance">
import { getToken } from '@/utils/auth'
import * as echarts from 'echarts'
import { ref } from '@vue/reactivity'
import { onMounted } from 'vue'
import { getInsuranceInfo, getInsuranceList } from '@/api/manage'
import { getUserInfo } from '@/api/login'
import { useRouter } from 'vue-router'
const router = useRouter()
const userType = ref('2')
const getUser = async () => {
  const token = getToken()
  if (token) {
    const res = await getUserInfo()
    if (res.code === 200) {
      userType.value = res.data.sysUser.userType
      if (res.data.sysUser.userType === '2') {
        getInfo()
      }
    }
  }
}
getUser()
const onNum = ref(0)
const offNum = ref(0)
const showEcharts = ref(false)
const getInfo = async () => {
  const res = await getInsuranceInfo()
  showEcharts.value = true
  onNum.value = res.data.onNum
  offNum.value = res.data.offNum
  setTimeout(() => {
    initEcharts()
  }, 500)
}
const getActive = (number) => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(number)
}
const getSearchList = () => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(active.value)
}
const pageSize = ref(20)
const pageNum = ref(1)
const loading = ref(false)
const finishedText = ref('')
const finished = ref(false)
const list = ref([])
const total = ref(0)
const value = ref('')
const getList = (num) => {
  let type = 1
  if (num) {
    type = parseInt(num) + 1
  }
  const params = { pageNum: pageNum.value, pageSize: pageSize.value }
  const data = { oper: type, searchContent: value.value }
  loading.value = true
  getInsuranceList(params, data).then((res) => {
    loading.value = false
    if (res.code === 200) {
      if (res.rows && res.rows.length > 0) {
        list.value = list.value.concat(res.rows)
        total.value = res.total
        if (list.value >= res.total || res.total <= pageSize.value) {
          finished.value = true
          finishedText.value = '没有更多数据了'
        } else {
          finished.value = false
          pageNum.value = pageNum.value + 1
        }
      } else {
        finished.value = true
        total.value = res.total
      }
    } else {
      finished.value = true
    }
  })
}
const toDetail = (id) => {
  console.log('id', id)
  router.push({
    path: '/insurancedetail',
    query: {
      id: id
    }
  })
}

const active = ref(0)
const initEcharts = () => {
  console.log('initEcharts')
  const myChart = echarts.init(
    document.getElementById('insuranceEcharts')
  )
  const option = {
    color: ['#0088FF', '#FFAB00'],
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: ['65%', '90%'],
        center: ['50%', '50%'],
        avoidLabelOverlap: false,
        // itemStyle: {
        //   borderRadius: 10,
        //   borderColor: '#fff',
        //   borderWidth: 2
        // },
        label: {
          show: true,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '14',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: onNum.value, name: '已参保人员' },
          { value: offNum.value, name: '未参保人员' }
        ]
      }
    ]
  }
  myChart.setOption(option)
}
onMounted(() => {
  // initEcharts()
})

</script>
<style>
.search .van-field__clear,.search .van-field__right-icon{
  margin-right: 15px;
}
</style>
<style scoped>
.container {
  background: #fff;
  padding: 20px 15px 0;
  min-height: calc(100vh - 66px);
}
.top-box{
  padding: 25px 15px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #bdbdbd;
  background-color: rgba(248, 250, 255, 1)
}
.top-box .charts{
  width: 110px;
  height: 110px;
}
.top-box .flex-item{
  display: flex;
  /* width: 50%; */
  justify-items: center;
}
.top-box .num{
  font-size: 14px;
  padding-left: 10px;
}
.top-box .nums{
  font-size: 20px;
}
.top-box .name{
  margin: 35px 0 15px;
  font-size: 14px;
}
.top-box .table{
  display: flex;
  justify-content: space-around;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 0;
}
.top-box .table-text{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.list{
  font-size: 14px;
}
.list .list-title{
  line-height: 64px;
  color: #101010;
  background:  rgba(108, 99, 255, 0.04);
  display: flex;
  justify-content: space-around;
}
.list .list-text{
  line-height: 54px;
  color: #616161;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(245, 245, 245, 1);
}
</style>
