<template>
  <div class="container">
    <div>
      <van-tabs v-model:active="active" @change="getActive">
        <van-tab title="日工时统计"></van-tab>
        <van-tab title="月工时统计"></van-tab>
      </van-tabs>
    </div>
    <div class="search margin-top20 margin-bottom20"  v-if="userType==='2'">
      <van-search v-model="value" placeholder="姓名/手机号/身份证号" left-icon="false" :right-icon="require('../../../assets/imgs/center/icon-search.svg')"  @search="getSearchList" />
    </div>
    <div class="list">
      <div class="list-title">
        <div>{{userType==='2'?'姓名':'月份'}}</div>
        <div v-if="active === 1">工资月份</div>
        <div v-if="active === 0">日期</div>
        <div>{{active === 0?'日工时':'本月工时'}}</div>
        <div>加班时长</div>
        <div v-if="active === 0">详情</div>
      </div>
      <van-list
        v-model:loading="loading"
        :finished-text="finishedText"
        :finished="finished"
        @load="getList"
        :offset="10"
        ref="vlist"
      >
      <div class="list-text" v-for="(item,i) in list" :key="i">
        <div>{{userType==='2'?item.person?.trueName:item.month}}</div>
        <div v-if="active === 1">{{item.month}}</div>
        <div v-if="active === 0">{{item.workDay}}</div>
        <div>{{active===0?item.workHours:item.workHoursByMonth}}</div>
        <div>{{active===0?item.overTimeHours:item.addHoursByMonth}}</div>
        <div v-if="active === 0" class="col" @click="toDetail(item.id)">查看</div>
      </div>
      </van-list>
    </div>
    <div v-if="finished && total===0" style="text-align:center;">
        <van-image
            width="240"
            height="275"
            src="https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/nothing.png"
          />
      </div>
    <!-- <div class="load-more">上划加载更多</div> -->
  </div>
</template>

<script setup name="Hours">
import { getToken } from '@/utils/auth'
import { ref } from '@vue/reactivity'
import { getHoursList } from '@/api/manage'
import { getUserInfo } from '@/api/login'
import { useRouter } from 'vue-router'
const router = useRouter()
const userType = ref('2')
const getUser = async () => {
  const token = getToken()
  if (token) {
    const res = await getUserInfo()
    if (res.code === 200) {
      userType.value = res.data.sysUser.userType
    }
  }
}
getUser()
const active = ref(0)
const getActive = (number) => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(number)
}
const getSearchList = () => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(active.value)
}
const pageSize = ref(20)
const pageNum = ref(1)
const loading = ref(false)
const finishedText = ref('')
const finished = ref(false)
const list = ref([])
const total = ref(0)
const value = ref('')
const getList = (num) => {
  let type = 1
  if (num) {
    type = parseInt(num) + 1
  }
  const params = { pageNum: pageNum.value, pageSize: pageSize.value }
  const data = { type: type, selectContent: value.value }
  loading.value = true
  getHoursList(params, data).then((res) => {
    loading.value = false
    if (res.code === 200) {
      if (res.rows && res.rows.length > 0) {
        // for (const item of res.rows) {
        //   item.createDate = ''
        //   if (item.createTime) {
        //     item.createDate = item.createTime.substring(5, 10)
        //   }
        // }
        list.value = list.value.concat(res.rows)
        total.value = res.total
        if (list.value >= res.total || res.total <= pageSize.value) {
          finished.value = true
          finishedText.value = '没有更多数据了'
        } else {
          finished.value = false
          pageNum.value = pageNum.value + 1
        }
      } else {
        total.value = res.total
        finished.value = true
      }
    } else {
      finished.value = true
    }
  })
}
const toDetail = (id) => {
  console.log('id', id)
  router.push({
    path: '/hoursdetail',
    query: {
      id: id,
      type: active.value
    }
  })
}
</script>
<style>
.search .van-field__clear,.search .van-field__right-icon{
  margin-right: 15px;
}
</style>
<style scoped>
.container {
  background: #fff;
  padding: 20px 15px 0;
  min-height: calc(100vh - 66px);
}

</style>
