<template>
  <div class="container">
    <div class="search margin-bottom20">
      <van-search v-model="value" placeholder="用工单位" left-icon="false" :right-icon="require('../../../assets/imgs/center/icon-search.svg')" />
    </div>
    <div class="list">
      <div class="list-title">
        <div>用工单位</div>
        <div>合同有效期</div>
        <div>详情</div>
      </div>
      <div class="list-text">
        <div>格力电器</div>
        <div>2020.06.02 - 2023.06.02</div>
        <div class="col">查看</div>
      </div>
      <div class="list-text">
        <div>邓芳芳</div>
        <div>2020.06.02 - 2023.06.02</div>
        <div class="col">查看</div>
      </div>
    </div>
    <div class="load-more">上划加载更多</div>
  </div>
</template>

<script setup name="Employer">
import { ref } from '@vue/reactivity'
const active = ref(0)
</script>
<style>
.search .van-field__clear,.search .van-field__right-icon{
  margin-right: 15px;
}
</style>
<style scoped>
.container {
  background: #fff;
  padding: 20px 15px 0;
  min-height: calc(100vh - 66px);
}
.list{
  font-size: 14px;
}
.list .list-title{
  line-height: 64px;
  color: #101010;
  background:  rgba(108, 99, 255, 0.04);
  display: flex;
  justify-content: space-around;
}
.list .list-text{
  line-height: 54px;
  color: #616161;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(245, 245, 245, 1);
}
</style>
