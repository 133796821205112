import request from '../utils/request'
// 获取轮播图
export function getBannerImg (query) {
  return request({
    url: '/appreq/index/getBannerImg',
    headers: {
      isToken: false
    },
    method: 'GET',
    params: query
  })
}
// 获取新闻搜索热榜 按阅读数排名
export function getTopNotice (query) {
  return request({
    url: '/appreq/index/getTopNotice',
    headers: {
      isToken: false
    },
    method: 'GET',
    params: query
  })
}
// 获取新闻列表
export function getNoticeList (data) {
  return request({
    url: '/appreq/index/getNoticeList',
    headers: {
      isToken: false
    },
    method: 'POST',
    params: data
  })
}
// 获取新闻详情
export function getNoticeDetail (id) {
  return request({
    url: '/appreq/index/getNoticeDetail/' + id,
    headers: {
      isToken: false
    },
    method: 'GET'
  })
}
// 添加新闻阅读数量
export function addReadCount (data) {
  return request({
    url: '/appreq/index/addReadCount',
    headers: {
      isToken: false
    },
    method: 'POST',
    data: data
  })
}
