<template>
  <div class="container">
    <div class="top-box">
      <div class="flex-between">
        <div class="flex-item">
          <van-image
            width="32"
            height="32"
            :src="require('../../../assets/imgs/center/on-job.png')"
          />
          <div class="num">
            <div>在职总人数</div>
            <div class="nums">{{onJobNum}}</div>
          </div>
        </div>
        <div class="flex-item">
          <van-image
            width="32"
            height="32"
            :src="require('../../../assets/imgs/center/out-job.png')"
          />
          <div class="num">
            <div>离职总人数</div>
            <div class="nums">{{leaveJobNum}}</div>
          </div>
        </div>
      </div>
      <div class="name">本月数据</div>
      <div class="table" >
        <div class="">
          <div class="font18 margin-bottom5">{{onJobNumByMonth}}</div>
          <div class="table-text">本月入职</div>
        </div>
        <div class="">
          <div class="font18 margin-bottom5">{{leaveJobNumByMonth}}</div>
          <div class="table-text">本月离职</div>
        </div>
        <div class="">
          <div class="font18 margin-bottom5">{{expireContractByMonth}}</div>
          <div class="table-text">合同到期</div>
        </div>
      </div>
    </div>
    <div class="margin-top20">
      <van-tabs v-model:active="active" @change="getActive">
        <van-tab title="在职人员"></van-tab>
        <van-tab title="离职人员"></van-tab>
        <van-tab title="黑名单"></van-tab>
      </van-tabs>
    </div>
    <div class="search margin-top20 margin-bottom20">
      <van-search v-model="value" placeholder="姓名/手机号/身份证号" left-icon="false" :right-icon="require('../../../assets/imgs/center/icon-search.svg')" @search="getSearchList" />
    </div>
    <div class="list">
      <div class="list-title">
        <div>姓名</div>
        <div>性别</div>
        <div>年龄</div>
        <div>详情</div>
      </div>
      <van-list
        v-model:loading="loading"
        :finished-text="finishedText"
        :finished="finished"
        @load="getList"
        :offset="10"
        ref="vlist"
      >
      <div class="list-text" v-for="(item,i) in list" :key="i">
        <div>{{active === 2?item.person?.trueName:item.trueName}}</div>
        <div v-if="active !== 2">{{item.sex === '0'?'男':'女'}}</div>
        <div v-if="active === 2">{{item.person?.sex === '0'?'男':'女'}}</div>
        <div v-if="active !== 2" >{{item.age}}岁</div>
        <div v-if="active === 2" >{{item.person?.age}}岁</div>
        <div class="col" v-if="active !== 2" @click="toDetail(item.id)">查看</div>
        <div class="col" v-if="active === 2" @click="toDetail(item.person.id)">查看</div>
      </div>
      </van-list>
    </div>
      <div v-if="finished && total===0" style="text-align:center;">
        <van-image
            width="240"
            height="275"
            src="https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/nothing.png"
          />
      </div>
    <!-- <div class="load-more">上划加载更多</div> -->
  </div>
</template>

<script setup name="Person">
import { ref } from '@vue/reactivity'
import { getbaseInfo, getPeopleList } from '@/api/manage'
import { useRouter } from 'vue-router'
const router = useRouter()

const active = ref(0)
const onJobNum = ref(0)
const leaveJobNum = ref(0)
const onJobNumByMonth = ref(0)
const leaveJobNumByMonth = ref(0)
const expireContractByMonth = ref(0)
const getInfo = () => {
  getbaseInfo().then((res) => {
    if (res.code === 200) {
      onJobNum.value = res.data.onJobNum
      leaveJobNum.value = res.data.leaveJobNum
      onJobNumByMonth.value = res.data.onJobNumByMonth
      leaveJobNumByMonth.value = res.data.leaveJobNumByMonth
      expireContractByMonth.value = res.data.expireContractByMonth
    }
  })
}
getInfo()
// 计算年数 (日期)
const checkYear = (date) => {
  // 当前时间
  const nowDate = new Date()
  // 传入的日期
  const parseDate = new Date(date)
  // 当前时间的年份
  const nowYear = nowDate.getFullYear()
  // 传入时间的日期
  const parseYear = parseDate.getFullYear()
  return nowYear - parseYear
}
const pageSize = ref(20)
const pageNum = ref(1)
const loading = ref(false)
const finishedText = ref('')
const finished = ref(false)
const list = ref([])
const total = ref(0)
const value = ref('')
const getActive = (number) => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(number)
}
const getSearchList = () => {
  pageNum.value = 1
  list.value = []
  finishedText.value = ''
  finished.value = false
  total.value = 0
  getList(active.value)
}
const getList = (num) => {
  let type = 1
  if (num) {
    type = parseInt(num) + 1
  }
  const params = { pageNum: pageNum.value, pageSize: pageSize.value }
  const data = { type: type, selectContent: value.value }
  loading.value = true
  getPeopleList(params, data).then((res) => {
    loading.value = false
    if (res.code === 200) {
      if (res.rows && res.rows.length > 0) {
        for (const item of res.rows) {
          if (active.value !== 2) {
            item.age = item.birthday ? checkYear(item.birthday) : 0
          } else {
            item.person.age = item.person?.birthday ? checkYear(item.person.birthday) : 0
          }
        }
        list.value = list.value.concat(res.rows)
        total.value = res.total
        if (list.value >= res.total || res.total <= pageSize.value) {
          finished.value = true
          finishedText.value = '没有更多数据了'
        } else {
          finished.value = false
          pageNum.value = pageNum.value + 1
        }
      } else {
        finished.value = true
        total.value = res.total
      }
    } else {
      finished.value = true
    }
  })
}
const toDetail = (id) => {
  router.push({
    path: '/persondetail',
    query: {
      id: id
    }
  })
}
</script>
<style>
.search .van-field__clear,.search .van-field__right-icon{
  margin-right: 15px;
}
</style>
<style scoped>
.container {
  background: #fff;
  padding: 20px 15px 0;
  min-height: calc(100vh - 66px);
}
.top-box{
  padding: 25px 20px;
  border-radius: 10px;
  color: #fff;
  background: linear-gradient(152.68deg, rgba(49,92,235,1) 18.65%,rgba(83,117,255,1) 100.59%);
}
.top-box .flex-item{
  display: flex;
  /* width: 50%; */
  justify-items: center;
}
.top-box .num{
  font-size: 14px;
  padding-left: 10px;
}
.top-box .nums{
  font-size: 20px;
}
.top-box .name{
  margin: 35px 0 15px;
  font-size: 14px;
}
.top-box .table{
  display: flex;
  justify-content: space-around;
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 0;
}
.top-box .table-text{
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
}
.list{
  font-size: 14px;
}
.list .list-title{
  line-height: 64px;
  color: #101010;
  background:  rgba(108, 99, 255, 0.04);
  display: flex;
  justify-content: space-around;
}
.list .list-text{
  line-height: 54px;
  color: #616161;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid rgba(245, 245, 245, 1);
}
</style>
