<template>
  <div class="container">
    <div class="title">{{news.noticeTitle}}</div>
    <div class="date">{{news.createTime}}</div>
    <div class="content" v-html="news.noticeContent"></div>
  </div>
</template>

<script setup name="News">
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { getNoticeDetail, addReadCount } from '@/api/index'
const route = useRoute()
const id = route.query.id
const news = ref({})
const getDetail = () => {
  getNoticeDetail(id).then((res) => {
    if (res.code === 200) {
      res.data.noticeContent = res.data.noticeContent.replace(/<img/g, '<img style="width:100%;"')
      news.value = res.data
    }
  })
}
getDetail()
const addReadCounts = () => {
  addReadCount({ noticeId: id }).then((res) => {})
}
addReadCounts()
</script>
<style scoped>
.container {
  background: #fff;
  padding: 20px 15px 0;
  min-height: calc(100vh - 66px);
}
.title{
  color: #212121;
  font-size: 18px;
  text-align: justify;
  line-height: 30px;
  margin-bottom: 15px;
}
.date{
  font-size: 12px;
  color: #424242;
  margin-bottom: 20px;
}
.content{
  font-size: 15px;
  color: #424242;
  line-height: 30px;
}
img{
  width: 100%!important;
}
</style>
