<template>
  <div class="container">
    <div class="top-box">
      <van-image
        width="80"
        height="80"
        round
        :src="avatar?avatar:require('../../assets/imgs/avatar.png')"
      />
      <div class="name">{{userName}}</div>
    </div>
    <div class="box">
      <div class="flex-between">
      <div class="flex">
        <van-image
        width="18"
        height="18"
        round
        :src="require('../../assets/imgs/icon-phone.png')"
      />
      <div class="phone-name">手机号</div>
      </div>
      <div class="phone">{{phonenumber}}</div>
    </div>
    <div class="btn" @click="logout">退出登录</div>
    </div>
  </div>
</template>
<script setup name="Index">
import { removeToken } from '@/utils/auth'

import { getUserInfo } from '@/api/login'
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
const router = useRouter()
const avatar = ref('')
const userName = ref('')
const phonenumber = ref('')
const info = () => {
  getUserInfo().then((res) => {
    if (res.code === 200) {
      const data = res.data.sysUser
      if (data.avatar) {
        avatar.value = data.avatar
      }
      userName.value = data.userName
      phonenumber.value = data.phonenumber
    }
  })
}
info()
// 退出登录
const logout = () => {
  router.push({ path: '/' })
  removeToken('token')
}
</script>
<style scoped>
.container{
  min-height: calc(100vh - 46px);
  background: #ededed;
}
.top-box{
  padding-top: 30px;
  text-align: center;
}
.name{
  font-size: 20px;
  color: #101010;
  margin-top: 10px;
}
.phone-name{
  color: #1A1A1A;
  font-size: 14px;
  padding-left: 10px;
}
.phone{
  color: #BDBDBD;
  font-size: 16px;
}
.box{
  margin: 40px 15px 0;
}
.flex-between{
  padding: 22px 15px;
  background: #fff;
  border-radius: 6px;
}
.btn{
  height: 62px;
  line-height: 62px;
  text-align: center;
  color: #FF3D00;
  font-size: 14px;
  background: #fff;
  border-radius: 6px;
  margin-top: 15px;
  font-weight: 600;
}
</style>
