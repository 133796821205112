<template>
  <div class="container">
    <div class="outer">
      <div class="top">
        <div>{{info.person?.trueName}}</div>
        <div>{{info.person?.sex === '0'?'男':'女'}}</div>
        <div>{{info.person?.age}}岁</div>
        <div>{{info.type === '1'?'有固定期限合同':'无固定期限合同'}}</div>
      </div>
      <div class="inner-box">
        <div>
          <label>合同编号：</label>{{info.contractNo}}
        </div>
        <div>
          <label>身份证号：</label>{{info.person?.idcard}}
        </div>
        <!-- <div>
          <label>工号：</label>A239
        </div> -->
        <div>
          <label>用工单位：</label>{{info.inUnit?.unitName}}
        </div>
        <!-- <div>
          <label>部门：</label>一车间
        </div> -->
        <div>
          <label>起始日期：</label>{{info.startTime}}
        </div>
        <div>
          <label>终止日期：</label>{{info.endTime}}
        </div>
        <!-- <div>
          <label>签订日期：</label>2018-08-31
        </div> -->
        <!-- <div>
          <label>签订地点：</label>康博大厦17楼1701
        </div> -->
        <div>
          <label>合同类型：</label>{{info.type === '1'?'首次签订':'续签'}}
        </div>
        <div>
          <label>合同状态：</label>
          <span class="col" v-if="info.status==='1'">未签署</span>
          <span class="col" v-if="info.status==='2'">有效</span>
          <span class="col" v-if="info.status==='3'">合同已到期</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="ContractDetail">
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { getContractDetail } from '@/api/manage'
const info = ref({})
const route = useRoute()
const id = route.query.id
// 计算年数 (日期)
const checkYear = (date) => {
  // 当前时间
  const nowDate = new Date()
  // 传入的日期
  const parseDate = new Date(date)
  // 当前时间的年份
  const nowYear = nowDate.getFullYear()
  // 传入时间的日期
  const parseYear = parseDate.getFullYear()
  return nowYear - parseYear
}

const getDetail = () => {
  getContractDetail(id).then((res) => {
    if (res.code === 200) {
      res.data.person.age = res.data.person.birthday ? checkYear(res.data.person.birthday) : 0
      info.value = res.data
    }
  })
}
getDetail()

</script>
<style scoped>
.container{
  padding: 20px 15px;
}
.outer{
  border-radius: 10px 10px 12px 12px;
  background: #315CEB;
}
.outer .top{
  color: #fff;
  font-size: 16px;
  line-height: 66px;
  display: flex;
  justify-content: space-around;
}
.inner-box{
  border-radius: 10px 10px;
  background: #fff;
  padding: 30px 0 15px;
  color: #1A1A1A 100%;
  font-size: 13px;

}
.inner-box div{
  margin-bottom: 15px;
  padding: 0 20px;
}
.inner-box label{
  width: 65px;
  color: #9e9e9e;
}
</style>
