<template>
  <div class="container">
    <div class="outer">
      <div class="top">
        <div>{{info.trueName}}</div>
        <div>{{info.sex === '0'?'男':'女'}}</div>
        <div>{{info.age}}岁</div>
        <div v-if="info.workStatus === '1'">在职</div>
        <div v-if="info.workStatus === '2'">离职</div>
        <div v-if="info.workStatus === '3'">黑名单</div>
      </div>
      <div class="inner-box">
        <div>
          <label>身份证号：</label>{{info.idcard}}
        </div>
        <!-- <div>
          <label>所在省份：</label>{{info.}}
        </div> -->
        <div>
          <label>常住地址：</label>{{info.liveAddress}}
        </div>
        <div>
          <label>手机号码：</label>{{info.phone}}
        </div>
        <div>
          <label>婚姻状况：</label>{{info.maritalName}}
        </div>
        <div>
          <label>民族：</label>{{info.nation}}
        </div>
        <div>
          <label>用工形式：</label><span class="col">{{info.employmentName}}</span>
        </div>
        <!-- <div>
          <label>入职日期：</label>{{info.}}
        </div> -->
        <!-- <div>
          <label>用工单位：</label>格力电器
        </div> -->
        <div>
          <label>岗位：</label>{{info.job?info.job:'无'}}
        </div>
        <div>
          <label>紧急联系人：</label>{{info.emergencyContact?info.emergencyContact:'无'}}
        </div>
        <div>
          <label>紧急联系电话：</label>{{info.emergencyPhone?info.emergencyPhone:'无'}}
        </div>
        <!-- <div>
          <label>招聘来源：</label>{{info.recruitFrom?info.recruitFrom:'无'}}
        </div>
        <div>
          <label>招聘团队：</label>{{info.recruitTeam?info.recruitTeam:'无'}}
        </div>
        <div>
          <label>业务员：</label>{{info.salesman?info.salesman:'无'}}
        </div> -->
        <div>
          <label>备注：</label>{{info.remark?info.remark:'无'}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="PersonDetail">
import { ref } from '@vue/reactivity'
import { useRoute } from 'vue-router'
import { getDicts, getPeopleDetail } from '@/api/manage'
const info = ref({})
const route = useRoute()
const id = route.query.id
// 计算年数 (日期)
const checkYear = (date) => {
  // 当前时间
  const nowDate = new Date()
  // 传入的日期
  const parseDate = new Date(date)
  // 当前时间的年份
  const nowYear = nowDate.getFullYear()
  // 传入时间的日期
  const parseYear = parseDate.getFullYear()
  return nowYear - parseYear
}
// 婚姻状况
const maritalStatus = ref([])
const getMaritalStatus = async () => {
  const res = await getDicts('marital_status')
  maritalStatus.value = res.data
}
getMaritalStatus()
const getMaritalName = (val) => {
  for (const item of maritalStatus.value) {
    if (item.dictValue === val) {
      return item.dictLabel
    }
  }
}
// 用工形式
const employmentType = ref([])
const getEmploymentType = async () => {
  const res = await getDicts('employment_type')
  employmentType.value = res.data
}
getEmploymentType()
const getEmploymentName = (val) => {
  for (const item of employmentType.value) {
    if (item.dictValue === val) {
      return item.dictLabel
    }
  }
}
const getDetail = async () => {
  const res = await getPeopleDetail(id)
  if (res.code === 200) {
    res.data.age = res.data.birthday ? checkYear(res.data.birthday) : 0
    res.data.maritalName = getMaritalName(res.data.maritalStatus)
    res.data.employmentName = getEmploymentName(res.data.employmentType)
    info.value = res.data
  }
}
getDetail()

</script>
<style scoped>
.container{
  padding: 20px 15px;
}
.outer{
  border-radius: 10px 10px 12px 12px;
  background: #315CEB;
}
.outer .top{
  color: #fff;
  font-size: 16px;
  line-height: 66px;
  display: flex;
  justify-content: space-around;
}
.inner-box{
  border-radius: 10px 10px;
  background: #fff;
  padding: 30px 0 15px;
  color: #1A1A1A 100%;
  font-size: 13px;

}
.inner-box div{
  margin-bottom: 15px;
  padding: 0 20px;
}
.inner-box label{
  width: 65px;
  color: #9e9e9e;
}
</style>
