import request from '../utils/request'
// 根据字典类型查询字典数据信息
export function getDicts (dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}
// 获取在职人数、离职人数、本月数据
export function getbaseInfo (query) {
  return request({
    url: '/appreq/companypeople/getbaseInfo',
    method: 'GET',
    params: query
  })
}
// 获取人员管理列表
export function getPeopleList (query, data) {
  return request({
    url: '/appreq/companypeople/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 人员管理详情
export function getPeopleDetail (id) {
  return request({
    url: '/appreq/companypeople/getDetail/' + id,
    method: 'GET'
  })
}

// 获取工资预支列表
export function getAdvanceList (query, data) {
  return request({
    url: '/appreq/advance/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 获取工资预支详情
export function getAdvanceDetail (id) {
  return request({
    url: '/appreq/advance/' + id,
    method: 'GET'
  })
}
// 劳动合同管理 获取基础信息
export function getContractInfo (query) {
  return request({
    url: '/appreq/contract/getbaseInfo',
    method: 'GET',
    params: query
  })
}
// 获取劳动合同列表
export function getContractList (query, data) {
  return request({
    url: '/appreq/contract/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 获取劳动合同详情
export function getContractDetail (id) {
  return request({
    url: '/appreq/contract/getDetail/' + id,
    method: 'GET'
  })
}
// 获取工时列表
export function getHoursList (query, data) {
  return request({
    url: '/appreq/workhours/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 获取工时详情
export function getHoursDetail (id) {
  return request({
    url: '/appreq/workhours/getDetail/' + id,
    method: 'GET'
  })
}
// 获取社保数量
export function getInsuranceInfo (query) {
  return request({
    url: '/appreq/sInsurance/getNumber',
    method: 'GET',
    params: query
  })
}
// 获取社保列表
export function getInsuranceList (query, data) {
  return request({
    url: '/appreq/sInsurance/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 获取社保详细
export function getInsuranceDetail (id) {
  return request({
    url: '/appreq/sInsurance/' + id,
    method: 'GET'
  })
}
// 获取医保数量
export function getMedicalInfo (query) {
  return request({
    url: '/appreq/yInsurance/getNumber',
    method: 'GET',
    params: query
  })
}
// 获取医保列表
export function getMedicalList (query, data) {
  return request({
    url: '/appreq/yInsurance/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 获取医保详细
export function getMedicalDetail (id) {
  return request({
    url: '/appreq/yInsurance/' + id,
    method: 'GET'
  })
}
// 获取工资列表
export function getSalaryList (query, data) {
  return request({
    url: '/appreq/salary/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 获取工资详细
export function getSalaryDetail (id) {
  return request({
    url: '/appreq/salary/getDetail/' + id,
    method: 'GET'
  })
}
// 获取结算管理列表
export function getSettlementList (query, data) {
  return request({
    url: '/appreq/settlement/getList',
    method: 'POST',
    params: query,
    data: data
  })
}
// 获取结算详细
export function getSettlementDetail (id) {
  return request({
    url: '/appreq/settlement/' + id,
    method: 'GET'
  })
}
