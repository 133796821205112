<template>
  <div class="container">
    <div class="outer" v-if="uid">
      <div class="top">
        <div>{{info.unitName}}</div>
      </div>
      <div class="inner-box">
        <div>
          <label>单位全称：</label>{{info.unitName}}
        </div>
        <div>
          <label>单位电话：</label>{{info.phone}}
        </div>
        <div>
          <label>单位地址：</label>{{info.address}}
        </div>
        <div>
          <label>法人姓名：</label>{{info.legalCertificateName}}
        </div>
        <div>
          <label>法人电话：</label>{{info.legalCertificatePhone}}
        </div>
        <div>
          <label>统一社会信用代码：</label>{{info.uscc}}
        </div>
        <!-- <div>
          <label>合作项目：</label>劳务派遣
        </div>
        <div>
          <label>合同起始日期：</label>2020.06.02
        </div>
        <div>
          <label>合同截止日期：</label>2020.06.02
        </div>
        <div class="flex">
          <label>合同概要：</label><div  style="width:calc(100% - 65px);padding: 0;text-align: justify;">合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要合同概要</div>
        </div> -->
      </div>
    </div>
    <div style="text-align:center;" v-if="!uid" >
      <van-image
        width="240"
        height="275"
        src="https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/insurance-h5/nothing.png"
      />
    </div>
  </div>
</template>

<script setup name="EmpDetail">
import { ref } from '@vue/reactivity'
import { getCompanyInfo } from '@/api/login'
import { useRoute } from 'vue-router'
const info = ref({})
const route = useRoute()
const uid = route.query.uid
const getDetail = () => {
  getCompanyInfo().then((res) => {
    if (res.code === 200) {
      info.value = res.data
    }
  })
}
if (uid) {
  getDetail()
}

</script>
<style scoped>
.container{
  padding: 20px 15px;
}
.outer{
  border-radius: 10px 10px 12px 12px;
  background: #315CEB;
}
.outer .top{
  color: #fff;
  font-size: 16px;
  line-height: 66px;
  /* display: flex; */
  padding-left: 30px;
  /* justify-content: space-around; */
}
.inner-box{
  border-radius: 10px 10px;
  background: #fff;
  padding: 30px 0 15px;
  color: #1A1A1A 100%;
  font-size: 13px;

}
.inner-box div{
  margin-bottom: 15px;
  padding: 0 20px;
}
.inner-box label{
  width: 65px;
  color: #9e9e9e;
}
</style>
