<template>
  <div class="container">
    <div class="outer">
      <div class="top">
        <div>{{info.inUnit?.unitName}}</div>
      </div>
      <div class="inner-box">
        <!-- <div>
          <label>结算单号：</label>HL160219001
        </div> -->
        <div>
          <label>用工单位：</label>{{info.inUnit?.unitName}}
        </div>
        <div>
          <label>结算项目：</label>{{info.project}}
        </div>
        <div>
          <label>结算金额：</label>{{info.money}}
        </div>
        <div>
          <label>开票日期：</label>{{info.makeInvoiceDate}}
        </div>
        <div>
          <label>到账日期：</label>{{info.receivedDate}}
        </div>
        <!-- <div>
          <label>操作人：</label>张文涛
        </div>
        <div>
          <label>是否开票：</label>A239
        </div>
        <div>
          <label>社保扣缴：</label>已开票
        </div>
        <div>
          <label>是否到账：</label>未到账
        </div> -->
        <div>
          <label>备注：</label>{{info.remark}}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup name="SetDetail">
import { useRoute } from 'vue-router'
import { getSettlementDetail } from '@/api/manage'
import { ref } from '@vue/reactivity'
const info = ref({})
const route = useRoute()
const id = route.query.id
const getDetail = () => {
  getSettlementDetail(id).then((res) => {
    if (res.code === 200) {
      info.value = res.data
    }
  })
}
getDetail()

</script>
<style scoped>
.container{
  padding: 20px 15px;
}
.outer{
  border-radius: 10px 10px 12px 12px;
  background: #315CEB;
}
.outer .top{
  color: #fff;
  font-size: 16px;
  line-height: 66px;
  padding-left: 30px;
  /* display: flex;
  justify-content: space-around; */
}
.inner-box{
  border-radius: 10px 10px;
  background: #fff;
  padding: 30px 0 15px;
  color: #1A1A1A 100%;
  font-size: 13px;

}
.inner-box div{
  margin-bottom: 15px;
  padding: 0 20px;
}
.inner-box label{
  width: 65px;
  color: #9e9e9e;
}
</style>
