<template>
  <div class="container">
    <div class="swipe margin-bottom20">
      <van-swipe :autoplay="3000" lazy-render>
        <van-swipe-item
          v-for="image in images"
          :key="image"
        >
          <img :src="image" class="swipe-img" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="inner">
      <div class="list-box">
          <div class="list-icon" @click="toRecruit">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/1.png')"
            />
            <div class="text">求职招聘</div>
          </div>
          <!-- <div  class="list-icon" v-if="userType === '2'"  @click="toManage('seek')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/2.png')"
            />
            <div class="text">求职</div>
          </div> -->
          <div class="list-icon" v-if="userType === '2'" @click="toManage('peosonnel')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/3.png')"
            />
            <div class="text">人员管理</div>
          </div>
          <div class="list-icon"  @click="toManage('contract')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/4.png')"
            />
            <div class="text">劳动合同</div>
          </div>
          <div class="list-icon" @click="toManage('hours')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/5.png')"
            />
            <div class="text">工时查询</div>
          </div>
         <div class="list-icon" @click="toManage('wages')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/6.png')"
            />
            <div class="text">工资查询</div>
          </div>
          <div class="list-icon" @click="toManage('insurance')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/7.png')"
            />
            <div class="text">社保信息</div>
          </div>
          <div class="list-icon" @click="toManage('medical')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/8.png')"
            />
            <div class="text">医保信息</div>
          </div>
          <div class="list-icon" @click="toManage('salary')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/9.png')"
            />
            <div class="text">工资预支</div>
          </div>
          <div class="list-icon" v-if="userType === '2'" @click="toManage('settlement')" >
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/10.png')"
            />
            <div class="text">结算管理</div>
          </div>
          <div class="list-icon" @click="toManage('employer')">
            <van-image
              width="50"
              height="50"
              :src="require('../../assets/imgs/index/11.png')"
            />
            <div class="text">用工单位</div>
          </div>
      </div>
    </div>
    <div class="news inner">
      <div class="flex" @click="toDetail(news.noticeId)">
        <div class="news-title">
          <div class="col-42 font16 news-left-top">新闻</div>
          <div class="font16 news-left-bottom">热榜</div>
        </div>
        <div class="news-content van-multi-ellipsis--l2">{{news.noticeTitle}}</div>
      </div>
    </div>
    <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh" > -->
      <van-list
        v-model:loading="loading"
        :finished-text="finishedText"
        :finished="finished"
        @load="getList"
        :offset="10"
        ref="vlist"
      >
        <div class="news-list">
          <div class="border-bottom" v-for="(item,i) in list" :key="i" @click="toDetail(item.noticeId)">
            <div v-if="imgUrl" class="flex-between">
              <div class="list-item">
                <div class="list-item-text van-multi-ellipsis--l2">{{item.noticeTitle}}</div>
                <div class="list-item-date">{{item.createTime}} {{item.readCount}}阅读</div>
              </div>
              <van-image width="124" height="80" :src="item.imgUrl" />
            </div>
            <div v-else class="flex">
              <div class="list-item">
                <div class="list-item-text van-multi-ellipsis--l2">{{item.noticeTitle}}</div>
                <div class="list-item-date">{{item.createTime}} {{item.readCount}}阅读</div>
              </div>
            </div>
          </div>
        </div>
      </van-list>
    <!-- </van-pull-refresh> -->

  </div>
</template>

<script setup name="Index">
import { getToken } from '@/utils/auth'
import { ref } from '@vue/reactivity'
import { getUserInfo } from '@/api/login'
import { getBannerImg, getTopNotice, getNoticeList } from '@/api/index'
import { Dialog } from 'vant'
import { useRouter } from 'vue-router'
const router = useRouter()
const userType = ref('2')
const unitId = ref()
const getIconList = async () => {
  const token = getToken()
  if (token) {
    const res = await getUserInfo()
    if (res.code === 200) {
      console.log('userType', res.data.sysUser.userType)
      userType.value = res.data.sysUser.userType
      if (res.data.sysUser.userType === '2') {
        unitId.value = res.data.id
      } else {
        unitId.value = res.data.unitId
      }
    }
  }
}
getIconList()
// banner
const images = ref([])
const getImgs = () => {
  getBannerImg().then((res) => {
    if (res.code === 200) {
      images.value = res.data
    }
  })
}
getImgs()
// 获取新闻搜索热榜 按阅读数排名
const news = ref({})
const getNews = () => {
  getTopNotice().then((res) => {
    if (res.code === 200) {
      news.value = res.data
    }
  })
}
getNews()
// 新闻列表
const pageSize = ref(20)
const pageNum = ref(1)
const loading = ref(false)
const finishedText = ref('')
const finished = ref(false)
// const refreshing = ref(false)
const list = ref([])
const total = ref(0)
const getList = () => {
  console.log('getList')
  loading.value = true
  const params = { pageNum: pageNum.value, pageSize: pageSize.value }
  getNoticeList(params).then((res) => {
    loading.value = false
    if (res.code === 200) {
      if (res.rows && res.rows.length > 0) {
        list.value = list.value.concat(res.rows)
        total.value = res.total
        if (list.value >= res.total || res.total <= pageSize.value) {
          finished.value = true
          finishedText.value = '没有更多数据了'
        } else {
          finished.value = false
          pageNum.value = pageNum.value + 1
        }
      } else {
        finished.value = true
      }
    }
  })
}
// 刷新列表
// const onRefresh = () => {
//   refreshing.value = true
//   finished.value = false
//   pageNum.value = 1
//   getList()
// }
// 查看新闻详情
const toDetail = (id) => {
  router.push({
    path: '/news',
    query: {
      id: id
    }
  })
}

// 人员管理
const toManage = (str) => {
  const token = getToken()
  if (token) {
    switch (str) {
      case 'peosonnel':
        router.push({
          path: '/person'
        })
        break
      case 'contract':
        router.push({
          path: '/contract'
        })
        break
      case 'hours':
        router.push({
          path: '/hours'
        })
        break
      case 'wages':
        router.push({
          path: '/wages'
        })
        break
      case 'insurance':
        router.push({
          path: '/insurance'
        })
        break
      case 'medical':
        router.push({
          path: '/medical'
        })
        break
      case 'salary':
        router.push({
          path: '/salary'
        })
        break
      case 'settlement':
        router.push({
          path: '/settlement'
        })
        break
      case 'employer':
        router.push({
          path: '/empdetail',
          query: {
            uid: unitId.value
          }
        })
        break
      default:
        router.push({
          path: '/'
        })
    }
  } else {
    Dialog.confirm({
      title: '提示',
      message: '请先登录~'
    })
      .then(() => {
        router.push({ path: '/login' })
      })
      .catch(() => {
        // on cancel
      })
  }
}
const toRecruit = () => {
  window.open('https://www.hualao.cn/m/')
}
</script>
<style scoped>
.container {
  background: #fff;
  padding-bottom: 50px;
}
.van-list{
  min-height: 100px!important;
  overflow: scroll;
}
.swipe {
  padding: 15px;
}
.swipe-img {
  width: 100%;
  height: 100px;
}
.list-box{
  padding: 30px 0 0;
  background: #F8FAFF;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}
.list-icon{
  width: 25%;
  margin-bottom: 20px;
}
.list-box-row{

  justify-content: space-between;

}
.text{
  color: #8F96A7;
  font-size: 12px;
  margin-bottom: 10px;
  text-align: center;
}
.margin-bottom0 .text{
  margin-bottom: 0;
}
.news-left-top{
  margin-top: 5px;
  line-height: 18px;
  font-weight: 700;
  font-style: italic;
  font-family:Impact, Haettenschweiler, ‘Arial Narrow Bold’, sans-serif;
}
.news-left-bottom{
  line-height: 18px;
  color: #315CEB;
  font-weight: 700;
  font-style: italic;
  font-family:Impact, Haettenschweiler, ‘Arial Narrow Bold’, sans-serif;
}
.news{
  padding: 30px 0 20px;
}
.news-title{
  width: 40px;
}
.news-content{
  width: calc(100% - 40px);
  font-size: 14px;
  text-align: justify;
}
.news-list{
  border-top: solid 10px #fafafa;
  padding: 0 15px;
}
.flex-between .list-item{
  width: calc(100% - 134px);
  padding: 15px 0;
}
.flex .list-item{
  width: 100%;
  padding: 15px 0;
}
.list-item-text{
  width: 100%;
  color: #212121;
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 20px;
}
.list-item-date{
  color: #BDBDBD;
  font-size: 12px;
}
</style>
